import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/home-view/HomeView.vue";

export const routes = {
  home: {
    path: "/",
    name: "home",
    component: HomeView,
  },
};

const router = createRouter({
  history: createWebHashHistory(),
  routes: Object.values(routes) as RouteRecordRaw[],
});

export default router;
