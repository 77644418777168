<script setup lang="ts">
import CommonButton from '@/components/CommonButton.vue';
import { Constants } from '@/infra/constants/constants';
import { WhatsAppInfra } from '@/infra/whatsapp/whatsapp_infra';
</script>

<template>
  <div class="call-to-action-text">
    <p class="title up-fade">Os <b class="best-prices">melhores preços em FIFOs</b> pro seu negócio direto no WhatsApp</p>
    <div style="height: 18px;"></div>
    <p class="text-description up-fade" style="animation-delay: .5s;">Os melhores preços pro seu negócio no atacado chegando pra você antes do seu concorrente
      receber.
      Compramos FIFOs direto da indústria e vendemos com muito desconto! Mas acaba rápido!</p>
    <div style="height: 32px;"></div>
    <div class="buttons">
      <CommonButton
        @click="() => {
          WhatsAppInfra.sendMessage({
            phoneNumber: Constants.whatsappNumber,
            message: 'Olá, gostaria de falar com o representante comercial.',
          });
        }"
        type="outlined"
      >Falar com representante comercial</CommonButton>
    </div>
  </div>
</template>

<style scoped>
p.title {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.call-to-action-text {
  width: 54%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .call-to-action-text {
    width: 100%;
  }
  
}

.text-description {
  color: #524654;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .buttons {
    justify-content: center;
  }
}

.best-prices {
  color: #882EB7;
}
</style>