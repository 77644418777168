export class FormatUtils {

  static formatPrice(price: number): string {
    return price.toFixed(2).replace('.', ',');
  }

  static formatDate(date: Date | null): string {
    if (!date) return '-';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

}