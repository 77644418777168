<script setup lang="ts">
import { ref } from 'vue';
import CartButton from './CartButton.vue';
// import CommonButton from './CommonButton.vue';
import CartModal from './modals/CartModal.vue';

const cartModal = ref<InstanceType<typeof CartModal>>();

const scrollTop = ref(0);

window.addEventListener('scroll', () => scrollTop.value = window.scrollY);

</script>

<template>
  <header class="container" :style="{
    boxShadow: scrollTop > 0 ? '0px 4px 4px rgba(0, 0, 0, 0.1)' : 'none',
  }">
    <CartModal ref="cartModal" />
    <img alt="Opa! Salvou Logo" src="@/assets/salvou_logo.svg" class="logo" />
    <div class="buttons">
      <!-- <CommonButton type="text">Ofertas</CommonButton>
      <CommonButton type="text">Contato</CommonButton> -->
      <CartButton @open-cart-modal="cartModal?.open()" />
    </div>
  </header>
</template>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #EADCF9;

  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  gap: 8px;
}

.logo {
  width: 193px;
}

.buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

@media screen and (max-width: 550px) {
  header {
    flex-direction: column;
    justify-content: center;
  }
}

</style>