<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { defineProps, defineEmits } from 'vue';

const props = defineProps(
  {
    icon: {
      type: String,
      default: null,
    },
    action: {
      type: String as PropType<'cta' | 'success' | 'error' | 'warning' | 'secondary'>,
      default: "cta",
    },
    type: {
      type: String as PropType<'filled' | 'outlined' | 'text'>,
      default: "filled",
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'large'>,
      default: "medium",
    },
  },
);

defineEmits<{
  (e: 'click', event: MouseEvent): void
}>();

const cssVars = computed(
  () => {
    return {
      "--btn-border-color": `var(--token-button-color-${props.action}-${props.type}-default-border-color)`,
      "--btn-border-color-hover": `var(--token-button-color-${props.action}-${props.type}-hover-border-color)`,
      "--btn-border-color-active": `var(--token-button-color-${props.action}-${props.type}-active-border-color)`,

      "--btn-background": `var(--token-button-color-${props.action}-${props.type}-default-background-color)`,
      "--btn-background-hover": `var(--token-button-color-${props.action}-${props.type}-hover-background-color)`,
      "--btn-background-active": `var(--token-button-color-${props.action}-${props.type}-active-background-color)`,

      "--btn-text-color": `var(--token-button-color-${props.action}-${props.type}-default-text-color)`,
      "--btn-text-color-hover": `var(--token-button-color-${props.action}-${props.type}-hover-text-color)`,
      "--btn-text-color-active": `var(--token-button-color-${props.action}-${props.type}-active-text-color)`,

      "--btn-gap": `calc(var(--token-button-spacing-large-gap) * 1px)`,

          
      "--btn-padding": `calc(var(--token-button-spacing-${props.size}-padding-vertical) * 1px) calc(var(--token-button-spacing-${props.size}-padding-horizontal) * 1px)`,
      "--btn-padding-left": props.icon ? `calc(var(--token-button-spacing-${props.size}-padding-left-with-icon) * 1px)` : `calc(var(--token-button-spacing-${props.size}-padding-horizontal) * 1px)`,
    };
  }
);

</script>

<template>
  <button class="common-button" @click="$emit('click', $event)" type="button" :style="cssVars">
    <div :class="`content typography-${props.size}`">
      <div :class="`bi ${icon}`" v-if="icon"></div>
      <slot></slot>
    </div>
  </button>
</template>

<style scoped>
button.common-button>.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--btn-gap);
}

button.common-button {
  text-align: center;
  border-radius: calc(var(--token-button-border-radius) * 1px);
  outline: none;
  border: none;
  font-weight: bold;
  background-color: var(--btn-background);
  border: 1px solid var(--btn-border-color);
  color: var(--btn-text-color);
  padding: var(--btn-padding);
  padding-left: var(--btn-padding-left);
}

button:hover {
  cursor: pointer;
  background-color: var(--btn-background-hover);
  border: 1px solid var(--btn-border-color-hover);
  color: var(--btn-text-color-hover);
}

button:active {
  background-color: var(--btn-background-active);
  border: 1px solid var(--btn-border-color-active);
  color: var(--btn-text-color-active);
}

/* #region  Button Typography */

.typography-small {
  font-family: var(--token-button-typography-small-font-family);
  font-weight: var(--token-button-typography-small-font-weight);
  line-height: var(--token-button-typography-small-line-height);
  font-size: calc(var(--token-button-typography-small-font-size) * 1px);
  letter-spacing: var(--token-button-typography-small-letter-spacing);
  margin-bottom: calc(var(--token-button-typography-small-paragraph-spacing) * 1px);
  text-decoration: var(--token-button-typography-small-text-decoration);
  text-transform: var(--token-button-typography-small-text-case);
}

.typography-medium {
  font-family: var(--token-button-typography-medium-font-family);
  font-weight: var(--token-button-typography-medium-font-weight);
  line-height: var(--token-button-typography-medium-line-height);
  font-size: calc(var(--token-button-typography-medium-font-size) * 1px);
  letter-spacing: var(--token-button-typography-medium-letter-spacing);
  margin-bottom: calc(var(--token-button-typography-medium-paragraph-spacing) * 1px);
  text-decoration: var(--token-button-typography-medium-text-decoration);
  text-transform: var(--token-button-typography-medium-text-case);
}

.typography-large {
  font-family: var(--token-button-typography-large-font-family);
  font-weight: var(--token-button-typography-large-font-weight);
  line-height: var(--token-button-typography-large-line-height);
  font-size: calc(var(--token-button-typography-large-font-size) * 1px);
  letter-spacing: var(--token-button-typography-large-letter-spacing);
  margin-bottom: calc(var(--token-button-typography-large-paragraph-spacing) * 1px);
  text-decoration: var(--token-button-typography-large-text-decoration);
  text-transform: var(--token-button-typography-large-text-case);
}

/* #endregion */
</style>