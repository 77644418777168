<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineEmits } from 'vue';

defineEmits<{
  (e: 'seeMoreOffers'): void;
}>();

</script>

<template>
  <div class="see-offers" @click="$emit('seeMoreOffers')">
    <p class="text">Veja nossas ofertas</p>
    <div class="arrow">
      <FontAwesomeIcon :icon="Icons.imported.faCaretDown" class="caret-down" />
    </div>
  </div>
</template>

<style scoped>

.see-offers {
  color: #882EB7;
  cursor: pointer;
  padding-top: 16px;
  animation: fade-in 2s forwards;
  opacity: 0;
  animation-delay: 1s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes jump {
  0% {
    transform: translate(0px, 0px);
  }
  
  100% {
    transform: translate(0px, -10px);
  }
}

.text {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  animation-duration: 1.5s;
  animation-name: jump;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.arrow {
  animation-duration: .5s;
  animation-name: arrow;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes arrow {
  0% {
    transform: translate(0px, 10px);
  }
  
  100% {
    transform: translate(0px, 0px);
  }
}


</style>