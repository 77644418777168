<script setup lang="ts">
import { FormatUtils } from '@/infra/utils/format.utils';
import { defineProps } from 'vue';

defineProps<{
  expiresAt: Date | null,
}>();

</script>

<template>
  <div class="expires-card" v-show="expiresAt">
    <p>Validade: {{ FormatUtils.formatDate(expiresAt) }}</p>
  </div>
</template>

<style scoped>
.expires-card {
  border-radius: 6px;
  border: 2px solid #882EB7;
  background: #FFF;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: #882EB7;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  width: fit-content;
}
</style>