import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AlertModal"], { ref: "alertModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["LoadingModal"], { ref: "loadingModal" }, null, 512 /* NEED_PATCH */),
    _createVNode(_component_router_view)
  ], 64 /* STABLE_FRAGMENT */))
}