<script setup lang="ts">
import { ref } from 'vue';
import { defineProps, defineEmits, defineExpose } from 'vue';

defineProps({
  canClose: {
    type: Boolean,
    default: true,
  }
});

defineEmits<{
  (e: 'on-close-by-button'): void
}>();

const blackout = ref<HTMLDivElement>();
const zIndexRef = ref<number>(2);

defineExpose({
  open: (params: { zIndex?: number } = {}) => {
    if (blackout.value) {
      blackout.value?.classList.add("open");
    }
    zIndexRef.value = params?.zIndex ?? 2;
  },
  close: closeButton,
});

function closeButton() {
  if (blackout.value) {
    blackout.value?.classList.remove("open");
  }
}

</script>

<template>
  <div class="blackout" :style="{
    zIndex: zIndexRef
  }" ref="blackout">
    <div class="common-modal">
      <slot></slot>
      <img class="close-btn" v-if="canClose" src="@/assets/icons/close_cross.svg" width="15" height="15"
        v-on:click="() => {
          closeButton();
          $emit('on-close-by-button');
        }" />
    </div>
  </div>
</template>

<style scoped>
.blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.blackout.open {
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--token-modal-color-background-color);
  border-radius: 32px;
  padding: 16px;
  z-index: 3;
  color: var(--token-modal-color-text-color);
}

.close-btn {
  position: absolute;
  top: 5%;
  left: 95%;
  transform: translate(-100%, 0%);
  z-index: 3;
  cursor: pointer;
  color: var(--token-modal-color-close-icon-color);
}
</style>

