import { Constants } from "@/infra/constants/constants";
import { Product } from "@/models/product_model";
import axios from "axios";
import { JsonSerializer } from "typescript-json-serializer";

export class ProductsV2Api {

  static readonly url = Constants.stage === "prod" ? "https://5oclj22679.execute-api.us-east-1.amazonaws.com/prod/productv2" : "https://o2b4ap6df7.execute-api.us-east-1.amazonaws.com/dev/productv2";

  static readonly axiosClient = axios.create({
    baseURL: ProductsV2Api.url,
  });

  static async getProducts(params: {
    companyId: string,
    limit: number,
    filters: {
      name: string,
      value: string,
    }[],
    lastEvaluatedKey: {
      id: string,
      b2bPrice: number
    } | null
  }): Promise<{
    products: Product[],
    lastEvaluatedKey: {
      id: string,
      b2bPrice: number
    } | null
  } | null> {
    try {
      let query = `?limit=${params.limit}`;
      if (params.lastEvaluatedKey) {
        query += `&id=${params.lastEvaluatedKey.id}&b2b_price=${params.lastEvaluatedKey.b2bPrice}`
      }
      if (params.filters.length > 0) {
        query += `&filters=${encodeURIComponent(`${params.filters.map(e => `name=${e.name}&value=${e.value}`).join('&')}`)}`;
      }
      const res = await ProductsV2Api.axiosClient.get(`/b2b-products/companyId/${params.companyId}/pagination${query}`);
      const data = res.data;
      const products = new JsonSerializer().deserializeObjectArray(data.responseList, Product) as Product[];
      return {
        products,
        lastEvaluatedKey: {
          id: data.LastEvaluatedKey.id,
          b2bPrice: data.LastEvaluatedKey.b2b_price
        }
      };
    } catch(e) {
      return null;
    }
  }
}