import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faMinus, faPlus, faTag, faTrash, faCartShopping, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export class Icons {
  static readonly imported = {
    faTag,
    faPlus,
    faTrash,
    faMinus,
    faCaretDown,
    faCartShopping,
    faChevronRight,
  };

  public static import() {
    library.add(this.imported);
  }
}