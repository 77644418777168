<script setup lang="ts">
import CommonModal from './CommonModal.vue';
import CommonButton from '../CommonButton.vue';
import { ref, defineExpose } from 'vue';

const titleElem = ref<HTMLHeadElement>();
const contentElem = ref<HTMLParagraphElement>();
const buttons = ref<{
  text: string;
  action: () => void;
  cta?: 'cta' | 'error' | 'warning' | 'secondary';
  type?: 'filled' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
}[]>([]);
const icon = ref<string>();
const iconColor = ref<string>();

const alertModal = ref<InstanceType<typeof CommonModal>>();

defineExpose({
  open: (params: {
    title: string,
    content: string,
    buttons?: {
      text: string;
      action: () => void;
      cta?: 'cta' | 'error' | 'warning' | 'secondary';
      type?: 'filled' | 'outlined' | 'text';
      size?: 'small' | 'medium' | 'large';
    }[],
    icon?: string,
    iconColor?: string,
    zIndex?: number,
  }) => {
    titleElem.value!.innerText = params.title;
    contentElem.value!.innerText = params.content;
    buttons.value = params.buttons ?? [
      {
        text: 'Ok',
        action: () => {
          alertModal.value?.close();
        },
      },
    ];
    icon.value = params.icon ?? 'exclamation-circle';
    iconColor.value = params.iconColor || 'var(--token-semantic-color-fg-default)';
    
    alertModal.value?.open({ zIndex: params.zIndex ?? 3 });
  },
  close: () => {
    alertModal.value?.close();
  },
});
</script>

<template>
  <CommonModal ref="alertModal">
    <div class="alert-modal">
      <div :class="`alert-icon bi bi-${icon}`" :style="{
        color: iconColor,
      }"></div>
      <h3 ref="titleElem" class="title-medium centralized-elem"></h3>
      <p ref="contentElem" class="centralized-elem regular-medium"></p>
      <div class="continue-options" ref="continueOptions">
        <CommonButton
          v-if="buttons[0] != undefined"
          :action="buttons[0]?.cta ?? 'cta'"
          :size="buttons[0]?.size ?? 'medium'"
          :type="buttons[0]?.type ?? 'filled'"
          @click="buttons[0]?.action"
          class="flex-grow-1"
        >{{ buttons[0]?.text }}</CommonButton>
        <CommonButton
          v-if="buttons[1] != undefined"
          :action="buttons[1]?.cta ?? 'cta'"
          :size="buttons[1]?.size ?? 'medium'"
          :type="buttons[1]?.type ?? 'filled'"
          @click="buttons[1]?.action"
          class="flex-grow-1"
        >{{ buttons[1]?.text }}</CommonButton>
      </div>
      <CommonButton v-if="buttons[2] != undefined"
        :action="buttons[2]?.cta ?? 'cta'"
        :size="buttons[2]?.size ?? 'medium'"
        :type="buttons[2]?.type ?? 'text'"
        @click="buttons[2]?.action"
        class="w-100"
      >
        {{ buttons[2]?.text }}
      </CommonButton>
    </div>
  </CommonModal>
</template>

<style scoped>

.alert-modal {
  max-width: 400px;
  text-align: center;
}

.alert-icon {
  width: 210px;
  height: 210px;
  font-size: 210px;
}

.alert-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.continue-options {
  display: flex;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
}

</style>