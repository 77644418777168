import { Cart } from "@/models/cart_model";
import { JsonSerializer } from "typescript-json-serializer";

export class CartStorage {

  private static readonly key = 'cart';
  private static readonly serializer = new JsonSerializer();

  static getCart(): Cart | null {
    const cartData = localStorage.getItem(this.key);
    if (!cartData) return null;
    const data = this.serializer.deserializeObject(cartData, Cart) as Cart;
    return data;
  }

  static setCart(cart: Cart) {
    const data = JSON.stringify(cart) as string | null | undefined;
    if (!data) return;
    localStorage.setItem(this.key, data);
  }

}