import { ProductsV2Api } from '@/infra/aws/products_v2/products_v2_api';
import { Constants } from '@/infra/constants/constants';
import { Product } from '@/models/product_model';
import { defineStore } from 'pinia';

interface IState {
  products: Product[];
  isLoaded: boolean;
  lastEvaluatedKey: {
    id: string;
    b2bPrice: number;
  } | null;
}

export const useProductsStore = defineStore('productsStore', {
  state: (): IState => ({
    products: [],
    isLoaded: false,
    lastEvaluatedKey: null,
  }),
  actions: {
    async fetchProducts(resetLastEvaluatedKey = false) {
      if (!this.hasMoreItems() && this.isLoaded) return;
      const data = await ProductsV2Api.getProducts({
        companyId: Constants.companyId,
        limit: 10,
        filters: [
          {
            name: "b2b-stock",
            value: "200",
          },
        ],
        lastEvaluatedKey: resetLastEvaluatedKey ? null : this.lastEvaluatedKey,
      });
      if (resetLastEvaluatedKey) this.products = [];
      this.products = this.products.concat(data?.products ?? []);
      this.lastEvaluatedKey = data?.lastEvaluatedKey ?? null;
      this.isLoaded = true;
    },
    hasMoreItems() {
      return this.lastEvaluatedKey && this.lastEvaluatedKey.id && this.lastEvaluatedKey.b2bPrice;
    }
  },
});
