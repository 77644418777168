<script setup lang="ts">
import { ref, defineExpose } from 'vue';
import CommonModal from './CommonModal.vue';
import { useCartStore } from '../../store/cart_store';
import CommonButton from '../CommonButton.vue';
import CartProductCard from '../CartProductCard.vue';
import { FormatUtils } from '@/infra/utils/format.utils';

const cartModal = ref<InstanceType<typeof CommonModal>>();

defineExpose({
  open: (params?: {
    zIndex?: number;
  }) => {
    cartModal.value?.open({ zIndex: params?.zIndex ?? 3 });
  },
  close: () => {
    cartModal.value?.close();
  },
});

const cartStore = useCartStore();

</script>

<template>
  <CommonModal ref="cartModal">
    <section class="cart-modal-father">
      <div>
        <div class="title-father">
          <h1 class="title">Carrinho</h1>
        </div>

        <div style="height: 32px;"></div>

        <div class="products-container">
          <CartProductCard
            v-for="item in Object.values(cartStore.cart.products)"
            :key="item.product.id"
            :product="item.product"
            :quantity="item.quantity"
            @add-to-cart="(cartAmount) => cartStore.addToCart(item.product, cartAmount)"
            @remove-from-cart="() => cartStore.removeFromCart(item.product)"
            @set-amount="(cartAmount) => cartStore.setAmount(item.product, cartAmount)"
          />
          <p
            class="empty-cart-text"
            v-if="Object.keys(cartStore.cart.products).length === 0"
          >Seu carrinho está vazio</p>
        </div>        
      </div>

      <div class="total-cart">
        <p class="total-text">Total: <b class="total-products-price">R$ {{ FormatUtils.formatPrice(cartStore.getTotalItemsPrice()) }}</b></p>
        <div style="height: 64px;"></div>
        <CommonButton
          @click="cartStore.sendToSeller()"
        >Enviar pedido para o representante comercial</CommonButton>
      </div>
    </section>
  </CommonModal>
</template>

<style scoped>
.cart-modal-father {
  color: #524654;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 1200px;
  height: 750px;
  max-width: 85vw;
  max-height: 95vh;
  padding: 40px 64px;
  overflow-y: auto;
}

@media screen and (max-width: 600px) {
  .cart-modal-father {
    padding: 16px;
  }
}

.products-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
}

.title-father {
  display: flex;
  width: 100%;
}

.title {
  font-weight: 800;
  font-size: 24px;
}

.total-products-price {
  font-size: 20px;
  font-weight: 800;
}

.total-cart {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.total-text {
  font-size: 20px;
  font-weight: 400;
}

.empty-cart-text {
  font-size: 20px;
  font-weight: 500;
}
</style>