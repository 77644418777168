import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "shimmer",
    style: _normalizeStyle({
    width: `${$props.width}px`,
    height: `${$props.height}px`,
  })
  }, null, 4 /* STYLE */))
}