import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class Photo {
  @JsonProperty()
  id: string;
  @JsonProperty()
  order: number;

  constructor(params: {
    id: string;
    order: number;
  }) {
    if (!params) return;
    this.id = params.id;
    this.order = params.order;
  }
}

@JsonObject()
export class StockInfo {
  @JsonProperty()
  packaging: number[];
  @JsonProperty()
  all_pallets: AllPallet[];
  @JsonProperty()
  total_stock: number;
  @JsonProperty()
  total_reserved_stock: number;

  constructor(params: {
    packaging: number[];
    all_pallets: AllPallet[];
    total_stock: number;
    total_reserved_stock: number;
  }) {
    if (!params) return;
    this.packaging = params.packaging;
    this.all_pallets = params.all_pallets;
    this.total_stock = params.total_stock;
    this.total_reserved_stock = params.total_reserved_stock;
  }
}

@JsonObject()
export class AllPallet {
  @JsonProperty()
  expires_at: number;
  @JsonProperty()
  address: number;
  @JsonProperty()
  stock: number;
  @JsonProperty()
  address_type: string;

  constructor(params: {
    expires_at: number;
    address: number;
    stock: number;
    address_type: string;
  }) {
    if (!params) return;
    this.expires_at = params.expires_at;
    this.address = params.address;
    this.stock = params.stock;
    this.address_type = params.address_type;
  }
}

@JsonObject()
export class ProductView {
  @JsonProperty()
  ean: string;

  constructor(params: {
    ean: string;
  }) {
    if (!params) return;
    this.ean = params.ean;
  }
}

@JsonObject()
export class Product {
  @JsonProperty()
  photos: Photo[];
  @JsonProperty()
  brand: string;
  @JsonProperty({
    type: StockInfo
  })
  stock_info: StockInfo;
  @JsonProperty()
  description: string;
  @JsonProperty()
  id: string;
  @JsonProperty()
  name: string;
  @JsonProperty({
    type: ProductView
  })
  product_views: ProductView[];
  @JsonProperty()
  b2b_price: number;
  @JsonProperty()
  sku: string;

  constructor(params: {
    photos: Photo[];
    brand: string;
    stock_info: StockInfo;
    description: string;
    id: string;
    name: string;
    product_views: ProductView[];
    b2b_price: number;
    sku: string;
  }) {
    if (!params) return;
    this.photos = params.photos;
    this.brand = params.brand;
    this.stock_info = params.stock_info;
    this.description = params.description;
    this.id = params.id;
    this.name = params.name;
    this.product_views = params.product_views;
    this.b2b_price = params.b2b_price;
    this.sku = params.sku;
  }
  
  getUnitsPerPackage() {
    if (!this.stock_info?.packaging.length) return 1;
    const unitsPerPackage = this.stock_info.packaging[0] ?? 1;
    return unitsPerPackage;
  }

  getUnitsPerPallet() {
    const stocks = this.stock_info.all_pallets.map((a) => a.stock);
    return Math.max(...stocks);
  }

  getTotalPackages() {
    if (!this.stock_info?.packaging.length) return 0;
    const unitsPerPackage = this.stock_info.packaging[0] ?? 1;
    return Math.floor(this.getAvailableStock() / unitsPerPackage);
  }

  getAvailableStock() {
    if (!this.stock_info) return 0;
    return this.stock_info.total_stock - (this.stock_info.total_reserved_stock ?? 0);
  }

  public getPhotoUrl() {
    if (!this.photos) return null;
    if (this.photos.length === 0) return null;
    return `https://cdnimages.opa4.store/productImages/${this.photos[0].id}`;
  }

  public getNextExpiresAt() {
    if (!this.stock_info) return null;
    if (!this.stock_info.all_pallets) return null;
    if (this.stock_info.all_pallets.length === 0) return null;
    const minExpiresAt = this.stock_info.all_pallets.sort((a, b) => {
      if (a.expires_at > b.expires_at) return 1;
      if (a.expires_at < b.expires_at) return -1;
      return 0;
    });
    const expiresAt = minExpiresAt[0]?.expires_at;
    if (!expiresAt) return null;
    return new Date(expiresAt);
  }

  public isRunningOutOfStock() {
    return this.getAvailableStock() < 50;
  }
}
