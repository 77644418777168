import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode($setup["HeaderComponent"]),
    _createVNode($setup["FlashySection"], {
      onSeeMoreOffers: _cache[0] || (_cache[0] = () => $setup.productsSection?.scrollToTop())
    }),
    _createVNode($setup["ProductsSection"], {
      products: $setup.productsStore.products,
      "is-loaded": $setup.productsStore.isLoaded,
      ref: "productsSection",
      onOnReachBottom: _cache[1] || (_cache[1] = () => $setup.productsStore.fetchProducts(false))
    }, null, 8 /* PROPS */, ["products", "is-loaded"])
  ]))
}