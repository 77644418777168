<script setup lang="ts">
import { ref, computed, onMounted, defineProps, defineEmits, defineExpose } from 'vue';
const props = defineProps<{
  modelValue: string;
  label?: string;
  supportingText?: string | null;
  state?: 'disabled' | 'error' | 'default';
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url' | 'search' | 'date' | 'time';
}>();

defineEmits<{
  (e: 'update:model-value', value: string) : void,
  (e: 'tap-trailing', evt: Event) : void,
  (e: 'leave-focus', value: string): void,
  (e: 'key-down', evt: KeyboardEvent): void,
  (e: 'on-paste', evt: ClipboardEvent): void,
}>();

defineExpose({
  setFocus: (v: boolean) => v ? inputField.value?.focus() : inputField.value?.blur(),
});

const state = props.state ?? 'default';

const isFocused = ref(false);
const isHovered = ref(false);
const floatOn = computed(() => isFocused.value || props.modelValue.length > 0)
const inputField = ref<HTMLInputElement | null>(null);
const inputBorderColor = `var(--token-input-${state}-border-color)`;
const inputBackgroundColor = `var(--token-input-${state}-background-color)`;

function getState() {
  if(isHovered.value && state != "disabled") {
    return "-hovered";
  }
  if(isFocused.value && state == "default") {
    return "-focused";
  }
  return `-${state}`;
}

onMounted(() => {
  inputField.value!.onkeyup = () => {
    inputField.value?.blur();
    inputField.value?.focus();
  };
});
</script>

<template>
  <div>
    <div class="input">
      <input
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        :disabled="state == 'disabled'"
        ref="inputField"
        class="common-input"
        :type="type"
        @focus="isFocused = true"
        @blur="isFocused = false"
        :value="modelValue"
        :style="`border-color: var(--token-input${getState()}-border-color)`"
        @input="$emit('update:model-value', ($event.target as HTMLInputElement).value)"
        @focusout="$emit('leave-focus', ($event.target as HTMLInputElement).value)"
        @paste="$emit('on-paste', ($event))"
        @keydown="$emit('key-down', ($event))"
      />

      <div :class="['semantic-typography-body-regular-default', 'input-field-label', 
        {'input-field-label-focused': floatOn, 'semantic-typography-body-bold-small' : floatOn}]"
        :style="`color: var(--token-input${getState()}-label-color)`">
        {{ label }}
      </div>

      <div v-if="modelValue.length" @mouseover="isHovered = true">
        <i class="bi bi-x-circle input-field-icon" @click="$emit('tap-trailing', $event)"
          :style="`color: var(--token-input${getState()}-icon-color)`"></i>
      </div>
    </div>
    <p class="mt-2 semantic-typography-body-regular-small" 
      :style="`color: var(--token-input${getState()}-supporting-text-color)`">
      {{ supportingText }}
    </p>
  </div>
</template>

<style scoped>
.common-input {
  color: var(--token-input-default-text-color);
  border-radius: 1000px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 42px;
  font-size: 16px;
  width: 100%;
  background-color: v-bind('inputBackgroundColor');
  border: 1px solid v-bind('inputBorderColor');
}

.input-field-icon {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 17px;
  right: 20px;
  background-color: var(--token-input-default-background-color);
  cursor: pointer;
}
.input-field-label {
  position: absolute;
  padding: 0px 4px 0px 4px;
  left: 21px;
  top: 35%;
  line-height: 16px;
  transition: all 0.1s;
  pointer-events: none;
}

.input-field-label-focused {
  position: absolute;
  top: 0%;
  transform: translate(0%, -50%);
  font-size: 12px;
  left: 20px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: var(--token-semantic-color-bg-surface);
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
  display: none;
}
</style>