import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13e24864"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expires-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, "Validade: " + _toDisplayString($setup.FormatUtils.formatDate($props.expiresAt)), 1 /* TEXT */)
  ], 512 /* NEED_PATCH */)), [
    [_vShow, $props.expiresAt]
  ])
}