<script setup lang="ts">
import { defineProps, defineExpose, defineEmits, ref } from 'vue';
import IconTitle from '@/components/IconTitle.vue';
import ProductCard from '@/components/ProductCard.vue';
import { Product } from '@/models/product_model';
import { useCartStore } from '@/store/cart_store';
import { Icons } from '@/font-awesome';
import ShimmerGFX from '@/components/ShimmerGFX.vue';
import { DOMUtils } from '@/infra/utils/dom.utils';

defineProps<{
  products: Product[],
  isLoaded: boolean,
}>();

const offersScrollTop = ref<HTMLElement>();
const offersBottom = ref<HTMLElement>();

defineExpose({
  scrollToTop: () => {
    if (offersScrollTop.value) {
      offersScrollTop.value?.scrollIntoView({ behavior: 'smooth' });
    }
  }
});

const emits = defineEmits<{
  (e: 'on-reach-bottom'): void;
}>();

const showedUp = ref(false);
window.addEventListener('scroll', () => {
  const previousShowedUp = showedUp.value;
  showedUp.value = DOMUtils.isInViewport(offersBottom.value!);
  if (showedUp.value && !previousShowedUp) emits('on-reach-bottom');
});

const cartStore = useCartStore();

</script>

<template>
  <section class="products-section container">
    <div ref="offersScrollTop"></div>
    <IconTitle :icon="Icons.imported.faTag" title="Ofertas"></IconTitle>
    <div style="height: 64px;"></div>
    <div class="products">
      <ProductCard
        v-show="isLoaded"
        v-for="product, index in products"
        :key="index"
        :product="product"
        :amount-in-cart="cartStore.getCartProduct(product.id)?.quantity"
        :stock-quantity="product.getAvailableStock()"
        @set-amount="(amount) => cartStore.setAmount(product, amount)"
        @remove-from-cart="cartStore.removeFromCart(product)"
        @add-to-cart="(amount) => cartStore.addToCart(product, amount)"
      >
      </ProductCard>
      <ShimmerGFX
        v-show="!isLoaded"
        v-for="index in 8"
        :key="index"
        :width="310"
        :height="507"
      ></ShimmerGFX>
    </div>
    <div ref="offersBottom"></div>
  </section>
</template>

<style scoped>
.products-section {
  background-color: #F4F2F6;
  padding-top: 148px;
  padding-bottom: 148px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 8px;
}

@media screen and (max-width: 1000px) {
  .products {
    flex-direction: column;
    align-items: center;
  }
}
</style>