<script setup lang="ts">
import { defineProps } from 'vue';

defineProps<{
  width: number;
  height: number;
}>();

</script>

<template>
  <div class="shimmer" :style="{
    width: `${width}px`,
    height: `${height}px`,
  }"></div>
</template>

<style scoped>

.shimmer {
  animation-duration: 3.5s;
  animation-name: shimmer;
  animation-direction: normal;
  animation-iteration-count: infinite;
  padding: 10px;
  padding-bottom: 4px;
  background-color: #EBEBEB;
  border-radius: 16px;

  max-width: 100%;
  max-height: 100%;

  mask: linear-gradient(-60deg, #000 40%, #0005, #000 60%) right/600% 100%;
}

@keyframes shimmer {
  0% {
    mask-position: right;
  }
  40% {
    mask-position: left;
  }
  100% {
    mask-position: left;
  }
}
</style>