<script setup lang="ts">
import { defineProps } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

defineProps<{
  icon: IconDefinition;
  title: string;
}>();

</script>

<template>
  <div class="info-tag">
    <FontAwesomeIcon :icon="icon" class="icon"/>
    <p>{{ title }}</p>
  </div>
</template>

<style scoped>
.info-tag {
  display: flex;
  gap: 8px;
  color: #1F2937;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  
  align-items: center;
}

.icon {
  font-size: 36px;
}
</style>