<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { FormatUtils } from '@/infra/utils/format.utils';
import { useCartStore } from '@/store/cart_store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref, defineEmits } from 'vue';

const cartStore = useCartStore();

const isMouseOver = ref(false);

defineEmits<{
  (e: 'open-cart-modal'): void
}>();

</script>

<template>
  <div class="cart-button"
    @mouseenter="isMouseOver = true"
    @mouseleave="isMouseOver = false"
    @click="$emit('open-cart-modal')"
  >
    <div class="cart-text-father">
      <div class="cart-text">
        <FontAwesomeIcon :icon="Icons.imported.faCartShopping" class="icon" />
        <div style="width: 8px;"></div>
        <p>Meu carrinho</p>
        <div style="width: 12px;"></div>
        <p class="amount">{{ cartStore.getItemsAmount() }}</p>
      </div>
      <div style="height: 4px;"></div>
      <p class="total-products-price">R$ {{ FormatUtils.formatPrice(cartStore.getTotalItemsPrice()) }}</p>
    </div>
    <div style="width: 22px;"></div>
    <FontAwesomeIcon :icon="Icons.imported.faChevronRight" :class="`icon ${isMouseOver ? 'moving' : ''}`" />
  </div>
</template>

<style scoped>
.cart-text {
  display: flex;
  align-items: center;
}

.cart-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px 16px 24px;
  border: 1px solid #524654;
  border-radius: 24px;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;
}

.cart-text-father {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cart-button p {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

p.amount {
  margin: 0;
  background-color: #FA5A27;
  color: #FFF;
  padding: 6px;
  min-width: 25px;

  border-radius: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 700px;

}

p.total-products-price {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
}

.icon {
  transition: all 1s ease-in-out;
}

.moving {
  animation-duration: 1s;
  animation-name: move;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes move {
  0% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(2px);
  }
}

</style>