import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c3ef43a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-modal" }
const _hoisted_2 = {
  ref: "titleElem",
  class: "title-medium centralized-elem"
}
const _hoisted_3 = {
  ref: "contentElem",
  class: "centralized-elem regular-medium"
}
const _hoisted_4 = {
  class: "continue-options",
  ref: "continueOptions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], { ref: "alertModal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(`alert-icon bi bi-${$setup.icon}`),
          style: _normalizeStyle({
        color: $setup.iconColor,
      })
        }, null, 6 /* CLASS, STYLE */),
        _createElementVNode("h3", _hoisted_2, null, 512 /* NEED_PATCH */),
        _createElementVNode("p", _hoisted_3, null, 512 /* NEED_PATCH */),
        _createElementVNode("div", _hoisted_4, [
          ($setup.buttons[0] != undefined)
            ? (_openBlock(), _createBlock($setup["CommonButton"], {
                key: 0,
                action: $setup.buttons[0]?.cta ?? 'cta',
                size: $setup.buttons[0]?.size ?? 'medium',
                type: $setup.buttons[0]?.type ?? 'filled',
                onClick: $setup.buttons[0]?.action,
                class: "flex-grow-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.buttons[0]?.text), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["action", "size", "type", "onClick"]))
            : _createCommentVNode("v-if", true),
          ($setup.buttons[1] != undefined)
            ? (_openBlock(), _createBlock($setup["CommonButton"], {
                key: 1,
                action: $setup.buttons[1]?.cta ?? 'cta',
                size: $setup.buttons[1]?.size ?? 'medium',
                type: $setup.buttons[1]?.type ?? 'filled',
                onClick: $setup.buttons[1]?.action,
                class: "flex-grow-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.buttons[1]?.text), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["action", "size", "type", "onClick"]))
            : _createCommentVNode("v-if", true)
        ], 512 /* NEED_PATCH */),
        ($setup.buttons[2] != undefined)
          ? (_openBlock(), _createBlock($setup["CommonButton"], {
              key: 0,
              action: $setup.buttons[2]?.cta ?? 'cta',
              size: $setup.buttons[2]?.size ?? 'medium',
              type: $setup.buttons[2]?.type ?? 'text',
              onClick: $setup.buttons[2]?.action,
              class: "w-100"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.buttons[2]?.text), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["action", "size", "type", "onClick"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}