<script setup lang="ts">
import { ref, defineExpose } from 'vue';
import CommonModal from './CommonModal.vue';

const loadingModal = ref<InstanceType<typeof CommonModal>>();

defineExpose({
  open: () => {
    loadingModal.value?.open();
  },
  close: () => {
    loadingModal.value?.close();
  },
});
</script>

<template>
  <CommonModal ref="loadingModal" :can-close="false">
    <div class="loading-modal">
      <div class="loading-spinner"></div>
      <div>
        <p>Carregando...</p>
      </div>
    </div>
  </CommonModal>
</template>

<style>
.loading-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  aspect-ratio: 1;
}

.loading-spinner {
  border: 4px solid var(--token-semantic-color-bg-surface);
  border-top: 4px solid var(--token-semantic-color-primary-default);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin .5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
