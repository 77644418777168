import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Product } from "./product_model";

@JsonObject()
export class CartProduct {
  @JsonProperty({
    type: Product,
  })
  product: Product;

  @JsonProperty()
  quantity: number;

  constructor(params: {
    product: Product;
    quantity: number;
  }) {
    if (!params) return;
    this.product = params.product;
    this.quantity = params.quantity;
  }
}

@JsonObject()
export class Cart {
  @JsonProperty({
    type: CartProduct,
  })
  products: CartProduct[] = [];

  constructor(params?: {
    products?: CartProduct[]
  }) {
    if (!params) return;
    this.products = params?.products ?? [];
  }

  getCartProduct(id: string): CartProduct | null {
    for (const product of this.products) {
      if (product.product.id === id) return product;
    }
    return null;
  }

  addProduct(cartProduct: CartProduct) {
    const product = this.getCartProduct(cartProduct.product.id);
    if (product) {
      product.quantity += cartProduct.quantity;
    } else {
      this.products.push(cartProduct);
    }
  }

  removeProduct(id: string) {
    const index = this.products.findIndex(product => product.product.id === id);
    if (index !== -1) {
      this.products.splice(index, 1);
    }
  }
}