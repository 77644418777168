<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineProps, defineEmits, ref } from 'vue';
import CommonInput from '../components/CommonInput.vue';

const props = defineProps<{
  amount: number;
  packageAmount: number;
  stockQuantity: number;
  style?: {
    position?: "absolute" | "relative" | "fixed" | "sticky" | "initial" | "inherit";
    transform?: string;
    top?: string;
    left?: string;
    width?: string;
    zIndex?: number;
  }
}>();

defineEmits<{
  (e: 'remove-from-cart', event: Event | null): void;
  (e: 'add-amount', amount: number, event: Event | null): void;
  (e: 'set-amount', amount: number, event: Event | null): void;
}>();

const showingContextMenu = ref(false);
const showingEditQuantity = ref(false);

function getAmount(i: number) {
  return (Math.floor((props.stockQuantity * i * 0.25) / props.packageAmount)) * props.packageAmount;
}

const editingValue = ref(props.amount.toString());

</script>

<template>
  <div
    class="plus-minus unselectable"
    @mouseleave="showingContextMenu = false; showingEditQuantity = false"
    :style="style"
  >
    <FontAwesomeIcon :icon="Icons.imported.faTrash" class="control-button" @click="$emit('remove-from-cart', $event)"
      v-if="packageAmount >= amount" />
    <FontAwesomeIcon :icon="Icons.imported.faMinus" class="control-button"
      @click="$emit('add-amount', -packageAmount, $event)" v-if="packageAmount < amount" />
    <div
      class="context-menu-open-btn"
      @click="showingContextMenu = !showingContextMenu"
    >
      <p>{{ amount }}</p>
      <FontAwesomeIcon :icon="Icons.imported.faCaretDown" class="caret-down" />
    </div>
    <FontAwesomeIcon :icon="Icons.imported.faPlus" class="control-button"
      @click="$emit('add-amount', +packageAmount, $event)" />

    <div class="context-menu" v-if="showingContextMenu">
      <p class="context-title">Quantidade</p>
      <p
        class="context-option"
        @click="$emit('set-amount', getAmount(i), $event); showingContextMenu = false; showingEditQuantity = false;"
        v-for="i in 4"
        :key="i"
      >{{ getAmount(i) }}</p>
      <p
        v-if="!showingEditQuantity"
        class="context-option"
        @click="showingEditQuantity = true"
      >Digitar quantidade específica</p>

      <div class="edit-quantity" v-if="showingEditQuantity">
        <CommonInput
          type="number"
          :model-value="editingValue"
          @update:model-value="(value) => editingValue = value"
          @key-down="(evt) => {
            if (evt.key === 'Enter') {
              const value = (evt.target as HTMLInputElement).value;
              $emit('set-amount', parseFloat(value), null);
              showingEditQuantity = false;
              editingValue = '';
            }
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.plus-minus {
  border-radius: 1000px;
  border: 1px solid #9CA3AF;
  background: #FFF;
  padding: 12px 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.context-menu-open-btn {
  cursor: pointer;
  width: 70px;
  color: #524654;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.context-menu-open-btn i {
  font-size: 12px;
}


.control-button {
  cursor: pointer;
  color: #882EB7;
  padding: 6px;
}

.caret-down {
  font-size: 14px;
}

.context-menu {
  background-color: #FFF;
  color: #524654;
  border-radius: 8px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #F8F4F9;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 12px;
  width: 300px;
  max-width: 100vw;

  position: absolute;
  transform: translate(-50%, 0);
  top: 100%;
  left: 50%;
  z-index: 100;
}

.context-title {
  padding: 12px 0px;
  background: #FFF;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
}

.context-option {
  padding: 8px 24px;
  cursor: pointer;
}

.context-option:hover {
  background-color: #F8F4F9;
}

.edit-quantity {
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>