import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69ac4ece"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flashy-section container" }
const _hoisted_2 = { class: "flashy-row" }
const _hoisted_3 = { style: {"margin-bottom":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["CallToAction"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["SeeOffers"], {
        onSeeMoreOffers: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('seeMoreOffers')))
      })
    ])
  ]))
}