export class Constants {
  static project = 'opatech-b2b';
  static stage = (window.localStorage.getItem("STAGE") as "prod" | "dev") ?? 'dev';

  static readonly companyFoundingDate = new Date(2019, 1, 1);

  static readonly companyId = Constants.stage === "prod" ? "greenstore" : "dev_greenstore";

  static readonly whatsappNumber = "+55 (19) 99664-6449";

}