import { Constants } from '@/infra/constants/constants';
import { CartStorage } from '@/infra/storage/cart_storage/cart_storage';
import { FormatUtils } from '@/infra/utils/format.utils';
import { WhatsAppInfra } from '@/infra/whatsapp/whatsapp_infra';
import { Cart, CartProduct } from '@/models/cart_model';
import { Product } from '@/models/product_model';
import { defineStore } from 'pinia';

interface IState {
  cart: Cart;
}

export const useCartStore = defineStore('cartStore', {
  state: (): IState => ({
    cart: CartStorage.getCart() ?? new Cart(),
  }),
  actions: {
    /**
     * @returns The total price of all items in the cart.
     */
    getTotalItemsPrice() {
      let price = 0;
      for (const cartProduct of this.cart.products) {
        price += cartProduct.product.b2b_price * cartProduct.quantity;
      }
      return price;
    },
    /**
     * @returns The amount of individual different items in the cart.
     */
    getItemsAmount() {
      return this.cart.products.length;
    },
    getCartProduct(id: string): CartProduct | null {
      return this.cart.getCartProduct(id);
    },
    setAmount(product: Product, amount: number) {
      const id = product.id;
      if (!id) return;
      let quantity = Math.max(Math.min(amount, product.getAvailableStock()), product.getUnitsPerPackage());
      quantity = Math.ceil(quantity / product.getUnitsPerPackage()) * product.getUnitsPerPackage();
      if (quantity > product.getAvailableStock()) quantity -= product.getUnitsPerPackage();
      const cartProduct = this.getCartProduct(id);
      if (!cartProduct) return;
      cartProduct.quantity = quantity;
      if (!cartProduct!.quantity || cartProduct!.quantity <= 0) this.removeFromCart(product);
      CartStorage.setCart(this.cart);
    },
    addToCart(product: Product, amount: number) {
      if (amount % product.getUnitsPerPackage() !== 0) return;
      const id = product.id;
      if (!id) return;
      this.cart.addProduct(new CartProduct({
        product,
        quantity: 0
      }));
      const cartProduct = this.getCartProduct(id);
      if (!cartProduct) return;
      cartProduct.quantity += amount;
      cartProduct.quantity = Math.max(cartProduct.quantity, 0);
      if (cartProduct.quantity > product.getAvailableStock()) cartProduct.quantity -= product.getUnitsPerPackage();

      if (cartProduct.quantity <= 0) {
        this.cart.removeProduct(id);
      }

      CartStorage.setCart(this.cart);
    },
    removeFromCart(product: Product) {
      const id = product.id;
      if (!id) return;
      this.cart.removeProduct(id);
      CartStorage.setCart(this.cart);
    },
    sendToSeller() {
      WhatsAppInfra.sendMessage({
        phoneNumber: Constants.whatsappNumber,
        message: `Olá, gostaria de fazer um orcamento para meu negócio:\n\n${this.cart.products.map(cartProduct => `- SKU ${cartProduct.product.sku} - QTD. ${cartProduct.quantity} - NOME ${cartProduct.product.name} - VALOR ${cartProduct.product.b2b_price}`).join('\n')}\n\nTotal: R$ ${FormatUtils.formatPrice(this.getTotalItemsPrice())}`,
      })
    },
  },
});
