import { createPinia } from 'pinia';
import DarkMode from "./infra/dark_mode/dark_mode";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

DarkMode.updateTheme();

import './assets/base.css';
import './assets/general.css';
import { Icons } from './font-awesome';

Icons.import();

createApp(App)
  .use(createPinia())
  .use(router)
  .mount("#app");
