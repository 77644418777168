<script setup lang="ts">
import { Product } from '@/models/product_model';
import { defineProps, defineEmits, ref } from 'vue';
import { FormatUtils } from '@/infra/utils/format.utils';
import PlusMinus from './PlusMinus.vue';
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExpiresCard from './ExpiresCard.vue';

const props = defineProps<{
  product: Product;
  amountInCart?: number;
  stockQuantity: number;
}>();

defineEmits<{
  (e: 'remove-from-cart', event: Event | null): void;
  (e: 'set-amount', amount: number, event: Event | null): void;
  (e: 'add-to-cart', amount: number, event: Event | null): void;
}>();

const photoUrl = props.product.getPhotoUrl();

const isHovered = ref(false);

</script>

<template>
  <div
    class="product-card"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    :style="{
      transform: isHovered ? 'scale(1.05)' : 'scale(1.0)'
    }"
  >
    <img
      v-if="photoUrl"
      :src="photoUrl"
      alt="Product image"
      class="image"
      :style="{
        transform: isHovered ? 'scale(1.15)' : 'scale(1.0)'
      }"
    />
    <div style="height: 36px;"></div>
    <p class="price">R$ {{ FormatUtils.formatPrice(product.b2b_price) }}</p>
    <div style="height: 16px;"></div>
    <p class="name">{{ product.name }}</p>
    <div style="height: 32px;"></div>
    <ExpiresCard :expiresAt="product.getNextExpiresAt()" />
    <div style="height: 16px;"></div>
    <div class="stock-info"  :style="{
      marginBottom: isHovered ? 'initial' : '-60px',
    }">
      <p class="stock">Estoque: {{ product.stock_info.total_stock }} un.</p>
      <p v-if="product.isRunningOutOfStock()" class="running-out-of-stock">ACABANDO</p>
    </div>

    <p class="stock show-data-animation" :style="{
      pointerEvents: isHovered ? 'auto' : 'none',
      animation: isHovered ? 'show-card-data 1s forwards' : 'none',
    }">Un. por caixa: {{ product.getUnitsPerPackage() }}</p>
    <p class="stock show-data-animation" :style="{
      pointerEvents: isHovered ? 'auto' : 'none',
      animation: isHovered ? 'show-card-data 1s forwards' : 'none',
    }">Total de caixas: {{ product.getTotalPackages() }}</p>

    <div
      class="add-button"
      v-if="(amountInCart || 0) <= 0"
      @click="$emit('add-to-cart', product.getUnitsPerPackage(), $event)"
      :style="{
        transform: isHovered ? 'scale(1.15)' : 'scale(1.0)',
      }"
    >
      <FontAwesomeIcon :icon="Icons.imported.faPlus" />
    </div>

    <PlusMinus v-if="(amountInCart || 0) > 0"
      :amount="amountInCart ?? 0"
      :packageAmount="product.getUnitsPerPackage()"
      :stockQuantity="stockQuantity ?? 0"
      @remove-from-cart="$emit('remove-from-cart', $event)"
      @set-amount="(amount, evt) => $emit('set-amount', amount, evt)"
      @add-amount="(amount, evt) => $emit('add-to-cart', amount, evt)"
      :style="{
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 30,
      }"
    ></PlusMinus>
  </div>
</template>

<style scoped>
.product-card {
  display: flex;
  width: 310px;
  max-width: 100%;
  height: 507px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 16px;
  border: 1px solid #D1D1D1;
  background: #FFF;

  padding: 24px 40px;

  position: relative;
  transition: all 0.4s ease-in-out;
}

.image {
  max-width: 200px;
  max-height: 200px;
  transition: all 0.8s ease-in-out;
}

.price {
  color: #882EB7;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.name {
  color: #524654;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre-wrasp;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-wrap: wrap;
  word-wrap: break-word;
}

.stock {
  color: #9F93A0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.stock-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  transition: all .5s ease-in-out;
}

.running-out-of-stock {
  color: #D83A4D;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;

  border-radius: 6px;
  border: 1px solid #D83A4D;
  padding: 4px 8px;
}

.add-button {
  display: flex;
  width: 52px;
  height: 52px;
  padding: 11.556px 13.722px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1000px;
  background: #EADCF9;
  color: #882EB7;
  font-size: 32px;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 40%;
  transform: translate(0, -50%);
  transition: all 0.8s ease-in-out;
}

.add-button:hover {
  background-color: #D8BDF4;
}

.show-data-animation {
  opacity: 0;
}

</style>

<style>
@keyframes show-card-data {
  0% {
    opacity: 0;
    bottom: -10px;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}
</style>