<script setup lang="ts">
import { Product } from '@/models/product_model';
import ExpiresCard from './ExpiresCard.vue';
import { FormatUtils } from '@/infra/utils/format.utils';
import { defineProps, defineEmits } from 'vue';
import PlusMinus from './PlusMinus.vue';
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

defineProps<{
  product: Product,
  quantity: number,
}>();

defineEmits<{
  (e: 'remove-from-cart', event: Event | null): void;
  (e: 'set-amount', amount: number, event: Event | null): void;
  (e: 'add-to-cart', amount: number, event: Event | null): void;
}>();

</script>

<template>
  <div class="cart-product-card">
    <div class="product-image-container">
      <img
        v-if="product.getPhotoUrl()"
        :src="product.getPhotoUrl()!"
        alt="Product image"
        class="product-image"
      />
      <div class="cart-product-info">
        <div class="product-info">
          <p class="name">{{ product.name }}</p>
          <div style="height: 4px;"></div>
          <p class="price">R$ {{ FormatUtils.formatPrice(product.b2b_price) }}</p>
        </div>
        <ExpiresCard :expires-at="product.getNextExpiresAt()" />
      </div>
    </div>
    <div class="cart-product-quantity-info">
      <div class="quantity-info">
        <PlusMinus
          :amount="quantity"
          :packageAmount="product.getUnitsPerPackage()"
          :stockQuantity="product.getAvailableStock()"
          @remove-from-cart="$emit('remove-from-cart', $event)"
          @set-amount="(amount, evt) => $emit('set-amount', amount, evt)"
          @add-amount="(amount, evt) => $emit('add-to-cart', amount, evt)"
          :style="{
            width: 'fit-content',
          }"
        ></PlusMinus>
        <p class="price-text-total">R$ {{ FormatUtils.formatPrice(product.b2b_price * quantity) }}</p>
      </div>
      <FontAwesomeIcon
        :icon="Icons.imported.faTrash"
        @click="$emit('remove-from-cart', $event)"
        class="trash-btn"
      />
    </div>
  </div>
</template>

<style scoped>

.cart-product-card {
  width: 100%;
  min-height: 135px;
  border: 1px solid #D4D4D4;
  border-radius: 8px;
  gap: 16px;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

@media screen and (max-width: 1010px) {
  div.product-image-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  div.cart-product-info {
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  div.product-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  div.cart-product-quantity-info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.product-image {
  width: 80px;
  height: 80px;
}

.cart-product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  font-weight: 500;
  font-size: 16px;
}

.price {
  font-weight: 800;
  font-size: 16px;
}

.cart-product-quantity-info {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 32px;
}

.quantity-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.price-text-total {
  font-weight: 800;
  font-size: 20px;
}

.trash-btn {
  cursor: pointer;
}

</style>