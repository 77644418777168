import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-208909df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["FontAwesomeIcon"], {
      icon: $props.icon,
      class: "icon"
    }, null, 8 /* PROPS */, ["icon"]),
    _createElementVNode("p", null, _toDisplayString($props.title), 1 /* TEXT */)
  ]))
}