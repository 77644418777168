<script setup lang="ts">
import CallToAction from '@/views/home-view/components/CallToAction.vue';
import SeeOffers from './SeeOffers.vue';
import { defineEmits } from 'vue';

defineEmits<{
  (e: 'seeMoreOffers'): void;
}>();

</script>

<template>
  <section class="flashy-section container">
    <div class="flashy-row">
      <CallToAction></CallToAction>
    </div>
    <div style="margin-bottom: 20px;">
      <SeeOffers @see-more-offers="$emit('seeMoreOffers')"></SeeOffers>
    </div>
  </section>
</template>

<style scoped>
.flashy-section {
  background-color: #EADCF9;
  min-height: calc(100vh - 109.6px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flashy-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 1200px) {
  .flashy-row {
    flex-direction: column;
    justify-content: center;
  }
}
</style>