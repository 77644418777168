<script setup lang="ts">
import { provide, ref } from 'vue';
import AlertModal from './components/modals/AlertModal.vue';
import LoadingModal from './components/modals/LoadingModal.vue';
import { AlertModalInjection, LoadingModalInjection } from './infra/constants/injections';

const alertModal = ref<InstanceType<typeof AlertModal>>();
const loadingModal = ref<InstanceType<typeof LoadingModal>>();

provide(AlertModalInjection, alertModal);
provide(LoadingModalInjection, loadingModal);

</script>

<template>
  <AlertModal ref="alertModal" />
  <LoadingModal ref="loadingModal" />
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
