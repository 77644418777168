<script setup lang="ts">
import HeaderComponent from '@/components/HeaderComponent.vue';
import FlashySection from './components/FlashySection.vue';
import ProductsSection from './components/ProductsSection.vue';
import { useProductsStore } from '@/store/products_store';
import { onMounted, ref } from 'vue';

const productsStore = useProductsStore();

onMounted(async () => {
  await productsStore.fetchProducts(true);
});

const productsSection = ref<InstanceType<typeof ProductsSection> | null>(null);

</script>

<template>
  <main>
    <HeaderComponent></HeaderComponent>
    <FlashySection @see-more-offers="() => productsSection?.scrollToTop()"></FlashySection>
    <ProductsSection
      :products="productsStore.products"
      :is-loaded="productsStore.isLoaded"
      ref="productsSection"
      @on-reach-bottom="() => productsStore.fetchProducts(false)"
    >
    </ProductsSection>
  </main>
</template>
